import { createBrowserRouter } from "react-router-dom";
import MomoKarmaPage from "../pages/MomoKarmaPage";
import Terms from '../pages/legal/Terms';
import Privacy from '../pages/legal/Privacy';
import AppKarmaResetPassword from '../pages/appkarma/account/ResetPassword';
import AppKarmaResetPasswordConfirmation from '../pages/appkarma/account/ResetPasswordConfirmation';
import AppKarmaVerifyEmailConfirmation from '../pages/appkarma/account/VerifyEmailConfirmation';
import AppKarmaEmailUnsubscribedConfirmation from '../pages/appkarma/account/EmailUnsubscribedConfirmation';

import CashKarmaResetPassword from '../pages/cashkarma/account/ResetPassword';
import CashKarmaResetPasswordConfirmation from '../pages/cashkarma/account/ResetPasswordConfirmation';
import CashKarmaVerifyEmailConfirmation from '../pages/cashkarma/account/VerifyEmailConfirmation';
import CashKarmaEmailUnsubscribedConfirmation from '../pages/cashkarma/account/EmailUnsubscribedConfirmation';

import RouteError from '../component/Error/RouteError';

export default createBrowserRouter([
  {
    path: "/",
    element: <MomoKarmaPage />,
    children: [
      {
        path: "legal/terms",
        element: <Terms />,
      },
      {
        path: "legal/privacy",
        element: <Privacy />,
      },
      {
        path: "*",
        element: <RouteError />
      }
    ],
  },
  {
    path: "appkarma/account/reset-password",
    element: <AppKarmaResetPassword />,
  },
  {
    path: "appkarma/account/reset-password-confirmation",
    element: <AppKarmaResetPasswordConfirmation />,
  },
  {
    path: "appkarma/account/verify-email-confirmation",
    element: <AppKarmaVerifyEmailConfirmation />,
  },
  {
    path: "appkarma/account/email-unsubscribed-confirmation",
    element: <AppKarmaEmailUnsubscribedConfirmation />,
  },
  {
    path: "cashkarma/account/reset-password",
    element: <CashKarmaResetPassword />,
  },
  {
    path: "cashkarma/account/reset-password-confirmation",
    element: <CashKarmaResetPasswordConfirmation />,
  },
  {
    path: "cashkarma/account/verify-email-confirmation",
    element: <CashKarmaVerifyEmailConfirmation />,
  },
  {
    path: "cashkarma/account/email-unsubscribed-confirmation",
    element: <CashKarmaEmailUnsubscribedConfirmation />,
  },

]);
