import { Button } from "@nextui-org/react";
import React from "react";
import CashKarmaLogo from "../../../component/Icons/CashKarma/CashKarmaLogo.js";
import VerifyEmailConfirmationIcon from "../../../component/Icons/CashKarma/VerifyEmailConfirmationIcon";

export default function VerifyEmailConfirmation() {
  return (
    <div
      className="h-screen flex flex-col items-center justify-center overflow-scroll"
      style={{ backgroundColor: "#111312", fontFamily: "Fira Sans, sans-serif" }}>
      <div className="mb-4">
        <CashKarmaLogo />
      </div>
      <div
        className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
        style={{ backgroundColor: "#1C1C1C" }}>
        <div className="rounded-md p-2 w-full">
          <div className="flex justify-center">
            <div>
              <VerifyEmailConfirmationIcon />
            </div>
          </div>
          <div className="text-center p-4 text-white">
            <div className="text-[#F5F5F5] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold'}}>Email Verified</div>
            <div className="text-[#BABABA] text-sm mt-4" style={{ fontFamily: 'Montserrat-Regular' }}>
              Your email has been verified successfully 
            </div>
          </div>
          <div className="px-3">
            <a href="http://www.momokarma.com" style={{ textDecoration: 'none' }}>
              <Button
                className="w-full mt-2 mb-4"
                style={{ backgroundColor: "#B1EDC4", fontFamily: 'FiraSans-Bold', borderRadius: '50px' }}>
              Log In
            </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
