import React from "react";

import { motion } from "framer-motion";
import RouteErrorImage from '../Images/MomoKarmaImages/RouteErrorImage.svg'
import { Image } from '@nextui-org/react';
import { Link } from 'react-router-dom';
export default function RouteError({ landingRef }) {
  const content = {
    title: (
      <div>
        <div className='xl:text-7xl lg:text-5xl text-4xl tracking-normal'>
          <div className='xl:mt-2 mt-1 font-bold' style={{ fontFamily: 'Plus Jakarta Sans',fontWeight: '600' }}>Page Not Found</div>
        </div>
      </div>
    ),
    leftSide: (
      <div className='lg:pt-2 pt-0'>
        <div>
          <p className='text-wrap xl:text-lg'>
            The page you are looking for is not here
          </p>
          <p className='text-wrap xl:text-lg'>Take me <Link href="/" className='text-primary'><span className='underline'>home</span></Link></p>
        </div>
        {/* <div className='lg:mt-7 mt-3'><Image src={MintKarmaIcon} alt='mintkarmaIcon' width={'166'} height={'48'} /></div> */}

      </div>
    ),
    rightSide: <Image src={RouteErrorImage} alt='Lannding Image' width={'624'} height={'624'} />,
  };

  return (
    <div
      ref={landingRef}
      id="landing_page"
      className="lg:px-10 px-2 lg:pt-0 pt-12 h-screen bg-white-400 flex flex-col justify-center md:flex-row lg:container mx-auto snap-start w-screen h-screen"
      style={{
        // background:
        //   "radial-gradient(50.00% 50.00% at 50% 50.00%, #686868 0%, #585858 100%)",
      }}
    >
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100, duration: 2 }}
        className="md:w-1/2 flex items-center justify-center p-4"
      >
        <div className="w-full">
          <div >{content.title}</div>
          <div className=" mg:mt-4 mt-2">{content.leftSide}</div>
        </div>
      </motion.div>
      <div className="md:w-1/2 flex items-center justify-center p-4">
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 100, duration: 2 }}
        >
          {content.rightSide}
        </motion.div>
      </div>
    </div>
  );
}

