import React from 'react'

import { motion } from 'framer-motion'
import AppKarmaIcon from '../Icons/AppIcons/AppKarmaIcon.svg'
import CashKarmaIcon from '../Icons/AppIcons/CashKarmaIcon.svg'
import LandingLeftImage from '../Images/MomoKarmaImages/LandingRightImage.svg'
import LandingRightBg from '../Images/MomoKarmaImages/LandingRightBg.svg'
import LandingThreePoints from '../Images/MomoKarmaImages/LandingThreePoints.svg'
import DividerIcon from "../Images/MomoKarmaImages/DividerIcon.svg"
import { Image } from '@nextui-org/react'
export default function Landing({ landingRef }) {
  const content = {
    title: (
      <div className="xl:text-7xl lg:text-6xl text-5xl tracking-normal font-bold">
        <div className="flex">
          <div className="pt-6">WE MAKE</div>
          <div className="pl-2">
            <Image
              className=""
              src={LandingThreePoints}
              alt={'line'}
              width={'60'}
              height={'55'}
            />
          </div>
        </div>
        <div className="mt-1" style={{ fontFamily: 'Mulish' }}>
          <div className="flex lg:items-center md:flex-row flex-col">
            <span className="text-[#147AFF]">REWARDS</span>
            <span className="md:ps-2">APPS</span>
          </div>
        </div>
      </div>
    ),
    leftSide: (
      <div className="lg:pt-2 pt-0">
        <div>
          <p className="text-wrap xl:text-lg">
            We have over 10 years Experience developing reward apps with
            millions in payouts and millions of happy users around the world.
          </p>
        </div>
        <div className="lg:mt-7 mt-3 flex gap-4">
          <div className="flex items-center gap-1">
            <Image
              src={AppKarmaIcon}
              alt="AppKarmaIcon"
              width={'40'}
              height={'40'}
            />
            AppKarma
          </div>
          <div className="lg:flex hidden items-center">
            <Image
              src={DividerIcon}
              alt="Divider logo"
              width={'4'}
              height={'4'}
            />
          </div>
          <div className="flex items-center gap-2">
            <Image
              src={CashKarmaIcon}
              alt="AppKarmaIcon"
              width={'40'}
              height={'40'}
            />
            CashKarma
          </div>
        </div>
      </div>
    ),
    rightSide: (
      <Image
        src={LandingLeftImage}
        alt="Lannding Image"
        width={'624'}
        height={'624'}
      />
    ),
  }

  return (
    <div
      ref={landingRef}
      id="landing_page"
      className="lg:px-10 px-2 lg:pt-0 pt-12 h-screen bg-white-400 flex flex-col justify-center md:flex-row lg:container mx-auto snap-start w-screen h-screen"
    >
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 100, duration: 2 }}
        className="md:w-1/2 flex items-center justify-center p-4"
        style={{
          backgroundImage: `url(${LandingRightBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="w-full">
          <div>{content.title}</div>
          <div className=" mg:mt-4 mt-2">{content.leftSide}</div>
        </div>
      </motion.div>
      <div className="md:w-1/2 flex items-center justify-center p-4">
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 100, duration: 2 }}
        >
          {content.rightSide}
        </motion.div>
      </div>
    </div>
  )
}
