import React from 'react'
import { motion } from 'framer-motion'
import AppKarmaSectionLeftImage from '../Images/MomoKarmaImages/AppKarmaSectionLeftImage.svg'
import AboutQRcode from '../Images/MomoKarmaImages/AboutQRcode.svg'
import Googleplay from '../Images/MomoKarmaImages/Googleplay.svg'
import Appstore from '../Images/MomoKarmaImages/Appstore.svg'

import { Image } from '@nextui-org/react'
export default function AppKarmaSection({ appKarmaRef }) {
  const content = {
    title: (
      <div>
        <div
          className="xl:text-[40px] lg:text-4xl text-3xl font-bold tracking-normal"
          style={{ fontFamily: 'Mulish' }}
        >
          <div className="">
            <span className="text-[#38D169]">AppKarma</span> Rewards
          </div>
          <div className="">
            <div className="xl:mt-2 mt-0">& Gift Cards</div>
          </div>
        </div>
      </div>
    ),
    leftSide: (
      <div className="lg:my-4 my-2">
        <div>
          <p className="text-wrap xl:text-lg">
            Greatest selection of games from all genres. Find and play to earn
            cash & gift cards from Amazon, iTunes, Google Play and many more
          </p>
        </div>
        <div className="lg:mt-7 mt-3 flex items-center">
          <div className="lg:block hidden p-4">
            <Image
              src={AboutQRcode}
              alt="mintkarmaIcon"
              width={'190'}
              height={'190'}
            />
          </div>
          <div className="lg:block flex lg:gap-0 gap-2 lg:mt-0 mt-4">
            <div className="lg:block hidden">Or download from:</div>
            <Image
              className="lg:my-2 my-0"
              src={Googleplay}
              alt="mintkarmaIcon"
              width={'193'}
              height={'54'}
            />
            <Image
              src={Appstore}
              alt="mintkarmaIcon"
              width={'193'}
              height={'54'}
            />
          </div>
        </div>
      </div>
    ),
    rightSide: (
      <div className="w-full">
        <Image
          src={AppKarmaSectionLeftImage}
          alt="About Image"
          width={'624'}
          height={'624'}
        />
      </div>
    ),
  }

  return (
    <div
      ref={appKarmaRef}
      id="appkarma_page"
      className="lg:px-10 px-2 h-screen bg-[#EEFBF2] rounded-lg flex flex-col justify-center md:flex-row lg:container mx-auto snap-start w-screen h-screen"
      style={
        {
          // background:
          //   "radial-gradient(50.00% 50.00% at 50% 50.00%, #686868 0%, #585858 100%)",
        }
      }
    >
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 100, duration: 2 }}
        className="md:w-1/2 flex items-center justify-center p-4"
      >
        <div className="w-full">
          <div>{content.title}</div>
          <div className=" mg:mt-4 mt-2 text-slate-700">{content.leftSide}</div>
        </div>
      </motion.div>
      <div className="md:w-1/2 flex items-center justify-center p-4">
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 100, duration: 2 }}
        >
          {content.rightSide}
        </motion.div>
      </div>
    </div>
  )
}
